import { throttle } from 'lodash-es';

import { storeLastViewedAcademyContent } from '@klaviyo-mkt/gatsby-components/organisms/universal-navigation/utils';

import { $skilljarUser } from '../stores/user';

import type { GatsbyBrowser } from 'gatsby';

const resizeIframe = throttle((height: number) => {
  if (!height) return;

  const iframeContainer = document.getElementById('klaviyo-frame-container');
  if (!iframeContainer) return;

  const additionalHeight = 0;
  const difference = Math.abs(iframeContainer?.offsetHeight - height);
  const exceedsThreshold = difference > (additionalHeight + 1);

  if (exceedsThreshold) {
    iframeContainer.style.height = `${height + additionalHeight}px`;
  }
}, 1000);

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  const VALID_ORIGINS = [
    'https://cms.academy.klaviyo.com',
    'https://learning-content.klaviyo.com',
    window.location.origin,
  ];

  window.addEventListener('message', ({ data, origin }) => {
    if (VALID_ORIGINS.includes(origin)) {
      if (data === 'skilljarRegister') {
        // Set data for Academy redirect location banner
        storeLastViewedAcademyContent(window.location.pathname);

        window.location.href = `https://cms.academy.klaviyo.com/auth/login?next=${encodeURIComponent(window.location.pathname)}`;
      }
      
      if (data?.iframeResize && data?.height) {
        resizeIframe(data.height);
      }

      if (data?.event === 'profilePageRedirect') {
        // profile page is nested and contains links, need top to navigate
        const target = data.link.replace('cms.academy', 'academy');
        window.location.href = target;
      }

      if (data?.skilljarUser) {
        if (data.skilljarUser.email) {
          window.klaviyo?.identify({
            email: data.skilljarUser.email,
            firstName: data.skilljarUser.firstName,
            lastName: data.skilljarUser.lastName,
          });
        }

        if (JSON.stringify($skilljarUser.get()) !== JSON.stringify(data.skilljarUser)) {
          $skilljarUser.set(data.skilljarUser);
        }
      }
    }
  }, false);
};
