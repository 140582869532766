import { debounce } from 'lodash-es';

import { windowIsDefined } from '@klv/shared/utils/functions';

import { getCookieValue } from './cookies';
import { hasStorageAvailable } from './storage';

// @ts-ignore @TODO: Update once utils/gclid is converted to TS.
import { readGclidFromLocalStorage } from './gclid';

import type { ICustomWindow } from './custom.window';

declare let window: ICustomWindow;

interface IPageTypes {
  [x: string]: string;
}

interface IGTMEvent {
  action?: string | string[];
  category?: string;
  label?: string;
  non_interaction_flag?: boolean;
}

interface IGTMButtonEvent {
  action?: string | string[];
  category?: string;
  label?: string;
  non_interaction_flag?: boolean;
  data_organism?: string;
}

interface IFormValues {
  company_size?: string;
  product_interest?: string;
  ecomm_platform?: string;
  field_1?: string;
  field_16?: string;
  field_10?: string;
  email?: string;
  demo_requested?: string;
  formId?: string;
  klaviyoFormDetail?: {
    [x: string]: string;
  };
}

const CONSENT_BYPASS_HOSTNAMES = [
  'local',
  'localhost',
];

const dataLayerHasConsentEvent = () => {
  try {
    if (window.dataLayerHasConsentEvent) {
      return true;
    }

    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      window.dataLayer.forEach((element) => {
        if (element?.event && element?.event === 'OneTrustGroupsUpdated') {
          window.dataLayerHasConsentEvent = true;

          return true;
        }

        return false;
      });
    }

    return false;
  } catch (error) {
    return false;
  }
};
// TODO: this object can be like 5 different shapes, so for now it is going to be an any. We should account for this.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dataLayerPush = async (value: any, retries = 0) => {
  let retryCount = retries || 0;
  if (dataLayerHasConsentEvent() || CONSENT_BYPASS_HOSTNAMES.includes(value?.hostname || value?.host)) {
    if (window.dataLayer) {
      return window.dataLayer.push(value);
    }
  }
  // retry for 10 seconds (10s / 250ms = 40 retries)
  if (retryCount < 40) {
    return setTimeout(async () => {
      retryCount += 1;
      await dataLayerPush(value, retryCount);
    }, 250);
  }

  return null;
};

export const decodeAndParse = (input = '') => {
  try {
    const decoded = atob(input);
    if (decoded) {
      return JSON.parse(decoded);
    }
    return '';
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const getPageExperimentInformation = (path = '') => {
  const experimentsCookie = getCookieValue('_kvyo_mkt_exp');
  const experimentPath = path ? path : window.location.pathname;
  const pathKey = experimentPath.replace('/', '_');

  if (experimentsCookie && pathKey) {
    const experiments = decodeAndParse(experimentsCookie);
    const experiment = experiments[pathKey];

    if (experiment) {
      return {
        'optimizely-experimentId': experiment?.experimentId || '',
        'optimizely-flagKey': experiment?.flagKey || '',
        'optimizely-ruleKey': experiment?.ruleKey || '',
        'optimizely-variationKey': experiment?.variationKey || '',
        'optimizely-experimentType': 'page',
      };
    }
  }

  return {};
};

export const getSitewideExperiments = () => {
  const experimentsCookie = getCookieValue('_kvyo_mkt_exp');

  if (experimentsCookie) {
    const experiments = decodeAndParse(experimentsCookie);
    if (experiments?.sitewide?.length > 0) {
      return experiments.sitewide.map((experiment: { experimentId: string; flagKey: string; ruleKey: string; variationKey: string; }) => {
        return {
          'optimizely-experimentId': experiment?.experimentId || '',
          'optimizely-flagKey': experiment?.flagKey || '',
          'optimizely-ruleKey': experiment?.ruleKey || '',
          'optimizely-variationKey': experiment?.variationKey || '',
          'optimizely-experimentType': 'sitewide',
        };
      });
    }
  }

  return [];
};

export const getSidewideExperimentForAction = (action = '') => {
  if (!action) return {};

  const experimentsCookie = getCookieValue('_kvyo_mkt_exp');

  if (experimentsCookie) {
    const experiments = decodeAndParse(experimentsCookie);
    if (experiments?.sitewide?.length > 0) {
      const experiment = experiments.sitewide.find(({ variables }: { variables?: { action?: string; }; }) => variables?.action?.includes(action));
      if (experiment) {
        return {
          'optimizely-experimentId': experiment?.experimentId || '',
          'optimizely-flagKey': experiment?.flagKey || '',
          'optimizely-ruleKey': experiment?.ruleKey || '',
          'optimizely-variationKey': experiment?.variationKey || '',
        };
      }
    }
  }

  return {};
};

const getPageType = () => {
  const options: IPageTypes = {
    '': 'home',
    features: 'platform',
    customers: 'customers',
    pricing: 'pricing',
    blog: 'blog',
    'marketing-resources': 'resource',
    events: 'events',
    partners: 'partners',
    grow: 'paid lp',
    'ecommerce-integrations': 'integrations',
    'email-marketing': 'email',
    'sms-marketing': 'sms',
  };
  const curPath = window.location.pathname.split('/')[1];
  if (options[curPath]) {
    return options[curPath];
  }

  return 'content';
};

const getFormattedDateTime = (startingDate: string | null = null) => {
  const date = startingDate ? new Date(startingDate).toString() : new Date().toString();

  return (date.indexOf('(') ? date.slice(0, date.indexOf('(')).trim() : date);
};

// event functions
const setOriginalLocation = async () => {
  if (!sessionStorage.getItem('hasSetOrigLoc')) {
    await dataLayerPush({
      originalLocation: `${document.location.protocol}//${
        document.location.hostname
      }${document.location.pathname
      }${document.location.search}`,
    }).then(() => sessionStorage.setItem('hasSetOrigLoc', 'true'));
  }

  return false;
};

const gtmOptimizelyTestEvents = () => {
  getSitewideExperiments().forEach((experiment: any) => {
    dataLayerPush({
      event: 'optimizelyTest',
      ...experiment,
      host: process.env.GATSBY_HOST ?? '',
      kvyoMktId: getCookieValue('_kvyo_mkt_id'),
    });
  });
  const pageTest = getPageExperimentInformation();
  if (pageTest['optimizely-experimentId']) {
    dataLayerPush({
      event: 'optimizelyTest',
      ...getPageExperimentInformation(),
      host: process.env.GATSBY_HOST ?? '',
      kvyoMktId: getCookieValue('_kvyo_mkt_id'),
    });
  }
  
};

const gtmBasicEvent = ({
  action, category, label, non_interaction_flag,
}: IGTMEvent) => {
  dataLayerPush({
    event: 'klaviyoEvent',
    non_interaction_flag: non_interaction_flag || false,
    category,
    action,
    label,
    host: process.env.GATSBY_HOST ?? '',
    kvyoMktId: getCookieValue('_kvyo_mkt_id'),
  });
};

const gtmButtonEvent = ({
  action, category, label, data_organism, non_interaction_flag,
}: IGTMButtonEvent) => {
  dataLayerPush({
    event: 'klaviyoEvent',
    non_interaction_flag: non_interaction_flag || false,
    category,
    action,
    label,
    data_organism,
    host: process.env.GATSBY_HOST ?? '',
    kvyoMktId: getCookieValue('_kvyo_mkt_id'),
  });
};

const gtmPageView = async () => {
  const storedValues = {
    account: false,
    loggedIn: false,
  };

  if (hasStorageAvailable('sessionStorage')) {
    const storedAccount = sessionStorage.getItem('account');
    storedValues.account = storedAccount ? JSON.parse(storedAccount) : false;
    const storedLoginStatus = sessionStorage.getItem('loggedIn');
    storedValues.loggedIn = storedLoginStatus ? JSON.parse(storedLoginStatus) : false;
  }

  // Google Tag Manager Events
  const pageViewObject = {
    event: 'pageview',
    timestamp: getFormattedDateTime(),
    non_interaction_flag: true,
    UAId: 'UA-30451006-13',
    userId: '',
    gaId: getCookieValue('_ga'),
    kvyoMktId: getCookieValue('_kvyo_mkt_id'),
    loggedIn: !!(storedValues.loggedIn),
    userIsExistingKlaviyoUser: !!(storedValues.account),
    host: process.env.GATSBY_HOST ?? '',
    hostname: window.location.hostname,
    url: window.location.href,
    pagePath: window.location.pathname,
    pageTitle: document.title,
    pageType: getPageType(),
    pageQuery: document.location.search,
    language: document.documentElement.lang,
  };
  await dataLayerPush(pageViewObject).then(() => setOriginalLocation());
};

const gtmFormEvent = async (formValues: IFormValues | null, type: string, event: string) => {
  let formEventObject = {
    event: 'klaviyoEvent',
    non_interaction_flag: (event === 'begin') ? true : false,
    category: 'form',
    action: type,
    label: event,
    host: process.env.GATSBY_HOST ?? '',
    kvyoMktId: getCookieValue('_kvyo_mkt_id'),
    ...getSidewideExperimentForAction(type),
  };
  if (event === 'complete' && formValues) {
    if (type === 'requestDemo') {
      const demoRequestFields = {
        leadListSize: formValues.company_size,
        userSignupIntent: formValues.product_interest,
        ecommPlatform: formValues.ecomm_platform,
        value: '',
        subscriber: formValues.field_1,
        userEmail: formValues.email,
        agency_flag: formValues.field_16?.includes('client'),
      };
      formEventObject = { ...formEventObject, ...demoRequestFields };
    }
    if (type === 'signup') {
      const signUpFields = {
        requestDemo: formValues.demo_requested,
        subscriber: formValues.field_1,
        userEmail: formValues.email,
        agency_flag: Boolean(formValues.field_16?.includes('client') || formValues?.field_10),
      };
      formEventObject = { ...formEventObject, ...signUpFields };
    }
    if (type === 'klaviyoForm') {
      const kFormData = {
        klaviyoFormId: formValues.formId,
        klaviyoFormDetail: formValues,
      };
      formEventObject = { ...formEventObject, ...kFormData };
    }
  }
  await dataLayerPush(formEventObject);
};

const gtmFormProgressEvent = async (type: string, fieldName: string) => {
  await dataLayerPush({
    event: 'klaviyoFormEvent',
    non_interaction_flag: false,
    category: 'form progress',
    action: type,
    label: fieldName,
    host: process.env.GATSBY_HOST ?? '',
    kvyoMktId: getCookieValue('_kvyo_mkt_id'),
    ...getSidewideExperimentForAction(type),
  });
};

const debouncedBeginEvent = debounce((id) => {
  if (id === 'demo') {
    gtmFormEvent(null, 'requestDemo', 'begin');
  }
  if (id === 'signup') {
    gtmFormEvent(null, 'signup', 'begin');
  }
}, 300);

export {
  debouncedBeginEvent, gtmOptimizelyTestEvents, gtmBasicEvent, gtmPageView, gtmFormEvent, gtmFormProgressEvent, gtmButtonEvent,
};

const getGoogleClientId = async () => {
  return new Promise<string>((resolve) => {
    if (windowIsDefined() && window?.gtag) {
      window.gtag('get', 'G-E2RS7HT946', 'client_id', (clientID: string) => {
        resolve(clientID);
      });
    } else {
      resolve('');
    }
  });
};

export const combineGclidAndGaIdValue = async (gclid: string) => {
  const gaId = await getGoogleClientId();
  const gclidStr = gclid || readGclidFromLocalStorage() || '';
  return `${gclidStr}:${gaId}`;
};
