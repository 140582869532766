import { gtmBasicEvent } from '@klv/shared/utils/google-tag-manager';
import { hasStorageAvailable } from '@klv/shared/utils/storage';

export const gtmClick = ({ childItem, menuItem }: { childItem: string; menuItem?: string; }) => {
  gtmBasicEvent({
    action: menuItem,
    category: 'header_nav',
    label: childItem,
  });
};

export const storeLastViewedAcademyContent = (path: string) => {
  if (hasStorageAvailable('sessionStorage')) {
    sessionStorage.setItem('lastViewedAcademyContent', JSON.stringify(path));
  }
};

export const loginActions = ({ childItem: label }: { childItem: string; }) => {
  const url = new URL(window.location.href);
  if (url.hostname.startsWith('academy') && url.hostname.endsWith('.klaviyo.com')) {
    if (location?.pathname.includes('/courses') || location?.pathname.includes('/collections')) {
      storeLastViewedAcademyContent(location.pathname);
    }
  }
  gtmClick({ childItem: label });
};
