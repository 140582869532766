import { Script, ScriptStrategy } from 'gatsby';

// HubSpot consent API types
type THubSpotConsent = {
  analytics: boolean;
  advertisement: boolean;
  functionality: boolean;
};

type TDoNotTrackConsent = {
  track?: boolean;
};

type THubSpotCommand = [string, TDoNotTrackConsent | THubSpotConsent ] | [string];

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    _hsp: THubSpotCommand[];
    _hsq: THubSpotCommand[];
  }
}

export const removeCookie = (name: string) => {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const handleConsent = (groups: string[]) => {
  const targetingConsentID = 'C0004';
  const functionalConsentID = 'C0003';
  const siteAnalyticsConsentID = 'C0008';

  // Set HubSpot consent from oneTrust
  // documentation: https://developers.hubspot.com/docs/reference/api/analytics-and-events/cookie-banner/cookie-banner-api#example-usage
  // as well as: https://my.onetrust.com/s/article/UUID-25510bd6-6447-4594-2b90-2f875b310773?language=en_US
  const hsp = window._hsp = window._hsp || [];
  const hsq = window._hsq = window._hsq || [];
  if (groups.includes(functionalConsentID)) {
    removeCookie('_kvyo_mkt_opt_out');
  } else {
    removeCookie('_kvyo_mkt_exp');
    removeCookie('_kvyo_mkt_id');

    const expiration = new Date();
    expiration.setFullYear(expiration.getFullYear() + 1);
    document.cookie = `_kvyo_mkt_opt_out=true; expires = ${expiration.toUTCString()};`;
  }
  if (groups.includes(targetingConsentID)) {
    hsq.push(['doNotTrack', { track: true }]); //enable tracking for user
  } else {
    hsq.push(['doNotTrack']); //turn off tracking for the user
    hsp.push(['revokeCookieConsent']); // revoke cookie consent
  }

  hsp.push([
    'setHubSpotConsent',
    {
      analytics: groups.includes(siteAnalyticsConsentID),
      advertisement: groups.includes(targetingConsentID),
      functionality: groups.includes(functionalConsentID),
    },
  ]);
};

/* @ts-ignore: e is OneTrust CustomEvent with no good types available */
export const onConsentChanged = (e) => {
  if (e && e?.detail && e?.detail?.length > 0) {
    handleConsent(e.detail);
  }
};

const OneTrust = ({
  scriptId = '',
}) => {

  if (!scriptId || (process.env.NODE_ENV === 'development' && process.env.INCLUDE_ONETRUST_IN_DEV !== 'true')) return null;

  return (
    <Script
      charSet="UTF-8"
      data-domain-script={scriptId}
      id='klaviyo-onetrust-script'
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      data-document-language="true"
      type="text/javascript"
      strategy={ScriptStrategy.postHydrate}
    />
  );
};

export default OneTrust;
