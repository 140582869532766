import { createContext, useCallback, useEffect, useState } from 'react';

import { hasStorageAvailable } from '@klaviyo-mkt/gatsby-components/shared/utils/storage';

import type { ReactNode } from 'react';

import type { IUserContext } from './types';


const getInitialViewedResources = () => {
  try {
    if (hasStorageAvailable('localStorage')) {
      const storedResources = localStorage.getItem('viewedResources');
      return storedResources ? JSON.parse(storedResources) : [];
    }

    return [];
  } catch {
    return [];
  }
};

const initialState = {
  trackViewedResource: () => {},
  viewedResources: [],
};

export const UserContext = createContext<IUserContext>(initialState);

export const UserContextProvider = ({ children }: { children: ReactNode; }) => {
  const [viewedResources, setViewedResources] = useState<string[]>(getInitialViewedResources());

  const trackViewedResource = useCallback((resourceId: string) => {
    if (!viewedResources.includes(resourceId)) {
      setViewedResources((resources) => [...resources, resourceId]);
    }
  }, [viewedResources]);

  // Update localStorage when viewedResources changes
  useEffect(() => {
    const storedResources = JSON.stringify(localStorage.getItem('viewedResources') || []);
    const stringifiedViewedResources = JSON.stringify(viewedResources);

    if (storedResources !== stringifiedViewedResources) {
      localStorage.setItem('viewedResources', stringifiedViewedResources);
    }
  }, [viewedResources]);

  return (
    <UserContext.Provider value={{
      trackViewedResource,
      viewedResources,
    }}>
      {children}
    </UserContext.Provider>
  );
};
